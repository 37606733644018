<template>
  <Wrapper class="max-w-2xl">
    <div class="mb-3 text-left">
      <h2 class="text-xl"><b>Información del usuario</b></h2>
      <p class="text-gray-500">
        Edite la información del usuario y el tipo de rol administrativo
      </p>
    </div>
    <form @submit.prevent="updateUser" class="mx-auto max-w-2xl">
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <BaseInput
          type="text"
          label="Nombres"
          placeholder="Ej: Carlos Andrés"
          v-model="form.name"
          :required="true"
        />
        <BaseInput
          type="text"
          label="Número de celular"
          v-model="form.phone"
          placeholder="Ej: +573103102001"
          :required="true"
        />
        <BaseInput
          type="text"
          label="Correo electrónico"
          v-model="form.email"
          placeholder="Ej: consumidor@consumidor.com"
          :disabled="true"
          :required="true"
        />
        <BaseInput
          type="text"
          label="Contraseña"
          v-model="form.password"
          placeholder="Opcional"
        />
        <BaseSelect
          label="Tipo de rol"
          :selectOptions="formOptions.optionsRoles"
          v-model="form.role"
          :required="true"
        />
      </div>
      <div v-if="feedback">
        <p class="mt-8 text-sm text-center text-red-500">{{ feedback }}</p>
      </div>
      <div
        class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
      >
        <Button
          type="submit"
          :block="true"
          :loading="loading"
          :disable="loading"
        >
          Guardar
        </Button>
        <Button
          type="button"
          @click.native="$router.go(-1)"
          :danger="true"
          :invert="true"
          :block="true"
          :disable="loading"
        >
          Cancelar
        </Button>
      </div>
    </form>
  </Wrapper>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      feedback: null,
      id: '',
      form: {},
    }
  },
  mounted() {
    const { user_id } = this.$route.params
    if (user_id) {
      this.id = user_id
      this.getDataUser()
    } else {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapState({ formOptions: 'form' }),
  },
  methods: {
    async getDataUser() {
      this.loading = true
      let response = await this.$api.users.getUser(this.id)
      this.loading = false

      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }

      this.form = { ...response.data }
    },
    async updateUser() {
      if (this.invalidForm()) return

      this.loading = true
      let response = await this.$api.users.updateUser(this.form, this.id)
      this.loading = false

      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }

      this.$showNotification('success', 'Usuario actualizado correctamente.')
      this.$router.go(-1)
    },
    invalidForm() {
      this.feedback = null

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = 'Introduzca el nombre completo del usuario'
        return true
      }
      if (this.$validator.isEmpty(`${this.form.phone}`)) {
        this.feedback = 'Introduzca un número de teléfono'
        return true
      }
      this.form.phone = this.form.phone.replace(/\s/g, '')
      if (!this.$validator.isMobilePhone(this.form.phone, 'es-CO')) {
        this.feedback = 'Introduzca un número de teléfono válido'
        return true
      }
      if (this.$validator.isEmpty(this.form.email)) {
        this.feedback = 'Introduzca un correo electrónico'
        return true
      }
      if (
        this.form.password &&
        !this.$validator.isStrongPassword(this.form.password)
      ) {
        this.feedback =
          'La contraseña debe contener mínimo: 8 caracteres, 1 mayúscula, 1 simbolo, 1 número'
        return true
      }
      if (this.$validator.isEmpty(this.form.role)) {
        this.feedback = 'Seleccione un rol'
        return true
      }
      return false
    },
  },
}
</script>

<style></style>
